import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import history from '../history';
import Login from "../pages/Login";
import PrivateRoute from "../components/PrivateRoute";
import RiskAnalysis from "../pages/RiskAnalysis";
import Upload from "../pages/Upload";
import selectModel from "../pages/selectModel";
import PatientErrorsPage from "../pages/PatientErrorsPage";

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/upload" component={Upload} />
        <Route path="/risk_analysis" component={RiskAnalysis} />
        <Route path="/selectModel" component={selectModel} />
        <Route path="/patient_data_errors" component={PatientErrorsPage} />

        <Route path='/'>
          <Login />
        </Route>

      </Switch>
    </Router>

  );
}
export default App;
