import AppBar from "@material-ui/core/AppBar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "../constants/wiai_logo.svg"
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { logOut } from "../actions/login";

const useStyles = makeStyles((theme) => ({
  highlight: {
      // borderBottom: '4px solid #BDD6FF',
      color: '#5591F8', 
      fontWeight: "600",
  },
  // '&::before':{
  //   position: 'absolute',
  //   backgroundColor:'#BDD6FF',
  //   height:'3px',
  //   width:'3px',

  // },
  heading: {
    color:'#5E6060',
    fontWeight: "400",
  },
  divider: {
    height: "24px",
    background: "black",
    alignSelf: "center"
},

}))

export default function MenuAppBar ({ userInfo }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const username = useSelector((state) => state.loginActionsReducer.loggedInUser)?.name
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const page = window.location.pathname;

  function handleLogout () {
    dispatch(logOut());
  }
  function handleModelChange () {
    window.sessionStorage.removeItem('model_type');
    window.location.href = '/selectModel';
  }

  const model_list = {
    "1" : "ANC Adherence",
    "2" : "Pregnancy Induced Hypertension",
    "3" : "C-Section",
}

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        elevation={1}
        style={{
          background: "white",
          height: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >

        <Toolbar>
          <a href='https://www.wadhwaniai.org/'>
          <img src={logo} alt="Logo" style={{  height:"2.5rem" , width:"9rem", marginLeft:'10px' }} />
          </a>
            
          <Grid item xs={2} md={2} lg={2}>
          </Grid>
          <Grid item xs={8} md={8} lg={8}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >

          <Button style={{ backgroundColor: 'transparent', textTransform: "none", marginLeft: "auto" }}
          onClick={() => { history.push('upload') }}>
          {/* <img src={require("../constants/patient_upload_icon.png")} style={{ width: "30px", height: "25px" }} alt="Logo" /> */}
          <Typography
          // className={headerOption === 0 ? classes.highlight : classes.heading}
          className={page === "/upload" ? classes.highlight : classes.heading}
              style={{
                // color: "#3C4043",
                fontSize: "14px",
                letterSpacing: "0.1px",
                marginLeft: "15px",
                padding: '7px',
              }}
            >
              Upload patient information
            </Typography>
          </Button>

          <Button style={{ backgroundColor: 'transparent', textTransform: "none", marginLeft: "30px" }}
          onClick={() => { history.push('risk_analysis') }}>
            {/* <img src={require("../constants/patient_risk_icon.svg")} style={{ width: "30px", height: "25px" }} alt="Logo" /> */}
            <Typography
            className={page === "/risk_analysis" ? classes.highlight : classes.heading}
              style={{
                // color: "#3C4043",
                fontSize: "14px",
                // fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                marginLeft: "8px",
                padding: '7px',
              }}
            >
              Adverse outcomes risk
            </Typography>
          </Button>
          <Button style={{ backgroundColor: 'transparent', textTransform: "none", marginLeft: "40px" }}
          onClick={() => { history.push('/patient_data_errors') }}>
            {/* <img src={require("../constants/patient_risk_icon.svg")} style={{ width: "30px", height: "25px" }} alt="Logo" /> */}
            <Typography
            // className={headerOption === 1 ? classes.highlight : classes.heading}
            className={page === "/patient_data_errors" ? classes.highlight : classes.heading}
              style={{
                fontSize: "14px",
                // fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                marginLeft: "8px",
                padding: '7px',
              }}
            >
              Patient Data Errors
            </Typography>

          </Button>
          <Button style={{backgroundColor:'#F0F6FF' , marginLeft:'auto', marginRight:'-50px', borderRadius: '12px', padding:'10px'}} onClick={handleModelChange}>
            <Typography style={{color:'#5591F8', fontSize:'14px', fontWeight:'600'}}>
              Model : {model_list[window.sessionStorage.getItem('model_type')]}
            </Typography>
          <img src={require("../constants/arrow_down.svg")} style={{ width: "20px", height: "20px"  }} alt="Logo" />

           
          </Button>


          </Grid>
          <Grid item xs={2} md={2} lg={2}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "right",
              flex: 1,
            }}
          >
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{
                color: "#EF7499",
                textTransform: "none"
              }}
            >
              <img src={require("../constants/logout.svg")} style={{ width: "45px", height: "45px" }} alt="Logo" />

              
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              style={{ marginRight: "20px" }}
            >
              <MenuItem onClick={handleLogout} >
                <ExitToAppIcon />
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
