import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux'
import check from "../constants/check.svg"

export default function PatientErrorTable ({ PatientErrorsPage }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)
    const patientErrors = useSelector(state => state.globalActionsReducer.patientErrors)
    const filled_circle = <img src={check} style={{border: '0.50px #54B288 solid',background: '#EBFFF6', borderRadius: 999,padding:'2px' }}/>
    const blank_circle = <div style={{height:'15px',width:'15px', border: '0.50px #C2C2C2 solid',background: '#FBFBFB', borderRadius: 999,padding:'2px' }}/>
    
    function matching_yes(str){
        if (str == null){
            return false
        }
        return ((typeof(str) == "boolean" && str == true) || (typeof(str) == "string" && str.toLowerCase() == 'yes'))
    } 

    return (
        <div>

        <TableContainer style={{ marginTop: "20px", maxHeight: "700px", overflowY: 'overlay' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>

                <Table stickyHeader aria-label="customized table" style={{ marginLeft: "35px", marginRight: "30px", color:'#EEF4FF' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6',borderTopLeftRadius:'16px'}}>MPID<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Visits Already<br/> Completed<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Height<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Weight<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Age<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Gravida<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6', borderTopRightRadius:'16px'}}>Error Columns<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: "white" }}>
                        {console.log(patientErrors)}
                        {patientErrors && patientErrors.map((sample,index) => {
                            return (
                            <>
                            
                            <TableRow key={index} hover>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A" , borderRight:'1px solid #E6E6E6', borderRight:'1px solid #E6E6E6'}}>{sample?.patient?.MPID}</TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6',display:'flex', justifyContent:'space-evenly' }}>{
                              
                                <>
                                    {matching_yes(sample?.patient?.data['ANC1']) ? filled_circle : blank_circle}
                                    {matching_yes(sample?.patient?.data['ANC2']) ? filled_circle : blank_circle}
                                    {matching_yes(sample?.patient?.data['ANC3']) ? filled_circle : blank_circle}
                                    {matching_yes(sample?.patient?.data['ANC4']) ? filled_circle : blank_circle}
                                </>
                                    
                                }</TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.patient?.height ? sample?.patient?.height + ' cm': '-' } </TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample.patient.weight ? sample.patient.weight + " kg" : '-' } </TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.patient?.age}</TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.patient?.data['Gravida']}</TableCell>
                                <TableCell align='center' style={{ textAlign: "center", fontWeight: '600',color: "#EF7499", borderRight:'1px solid #E6E6E6' }}>{sample?.error_columns.join(', ')}</TableCell>

                            </TableRow>
                            </>
                        )
                        })}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
        </div>

    )
}
