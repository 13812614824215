import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { DOWNLOAD_DATA_API } from "../env/index";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    button: {
        // margin: theme.spacing(1),
        color: "white",
        fontSize: "14px",
        height: "40px",
        width: "170px",
        paddingLeft: "12px",
        paddingRight: "12px",
        textTransform: "none",
        backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
        "&.Mui-disabled": {
            color: "white",
            background: "#BDC1C6",
            backgroundColor: "#BDC1C6",
        }
    },
    popover: {
        "& .MuiPaper-root": {
            color: "white",
            backgroundColor: "black"
        }

    }
}))

export default function FileErrors({fileData, index}){

    const classes = useStyles()


    // console.log(fileData)
    return (
        <>
        <Grid container style={{justifyContent: "center", marginTop: "10px"}} >
            <Grid item xs={12} md={12} lg={12} style={{backgroundColor: '#FFF',borderRadius: '16px', paddingBottom:'1rem'}}>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} lg={12} style={{marginTop: "15px"}}>                    
                        <div style={{display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px"}}>
                            <img src={require("../constants/xls.svg")} style={{width:"40px", height: "50px"}} alt="Logo" />
                            {/* <div style={{flexDirection:'column'}}> */}
                            <Typography style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                                alignItems: "center",
                                color: "#9AA0A6",
                                marginLeft: "20px"
                                }}>
                                {fileData?.original_file.split('/').pop().substring(0, 20)}... <br/>
                            </Typography>
                            

                            <img src={require("../constants/patient_icon.svg")} style={{marginLeft: "25px", width:"19px", height: "19px"}} alt="Logo" />
                            
                            <Typography style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                                alignItems: "center",
                                color: "#9AA0A6",
                                marginLeft: "20px"
                            }}>
                                Patients: {fileData.total_records} <br/>
                            </Typography>
                            </div>
                        <Typography style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "23px",
                            letterSpacing: "0.1px",
                            alignItems: "center",
                            color: "#9AA0A6",
                            marginLeft: "20px"
                            }}>
                            On {new Date(fileData.uploaded_at).toDateString()} at {new Date(fileData.uploaded_at).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit'  })}
                        </Typography>

                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>

    )

}