import { LOGOUT, SET_LOGIN_USER, LOGIN_FAILURE } from "./types";
import { loginFetchApi } from "./fetchApi";
import { createBrowserHistory } from 'history';

import { LOGIN_API } from "../env";

const history = createBrowserHistory()

export function login(username, password) {

    var formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    return dispatch => {
      return loginFetchApi(LOGIN_API, "POST", formdata)
        .then(result => {
          if (result !== null && result?.token !== null && result?.token !== undefined){
            window.sessionStorage.setItem('loggedInUser', JSON.stringify(result));
            dispatch(setLoginUser(result))
          }
          else{
            dispatch(loginFailure())
          }
        }
        );
    };
}


export function setLoginUser (data) {
  return {
    type: SET_LOGIN_USER,
    data
  };
}

export function loginFailure () {
  window.sessionStorage.setItem('loggedInUser', JSON.stringify(""));
  history.push('/login')
  return {
    type: LOGIN_FAILURE,
  };
}

export function logOut () {
  return dispatch => {
    dispatch({ type: LOGOUT });
    window.sessionStorage.removeItem('loggedInUser')
  };
}
export function getLoginUser (data) {
  return dispatch => {
    dispatch(setLoginUser(data));
  };
}
