import React, { useEffect } from 'react';
import { Chip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux'
import check from "../constants/check.svg"

export default function PatientDataTable2 ({ patientData }) {
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)


    return (
        <div>
        <TableContainer style={{ marginTop: "20px", maxHeight: "700px", overflowY: 'overlay' }}>
            <div style={{ display: 'flex', justifyContent: 'center'}}>


                <Table stickyHeader aria-label="customized table" style={{ marginLeft: "35px", marginRight: "30px"}}>
                    <TableHead >
                        <TableRow>
                        <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700", borderTopLeftRadius:'16px', borderRight:'1px solid #E6E6E6' }}>MPID<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Height<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Weight<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Age<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Gravida<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Category<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700", borderRight:'1px solid #E6E6E6' }}>Facility Type<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "0%", textAlign: "center", color: "#444", fontWeight: "700" , borderRight:'1px solid #E6E6E6'}}>Risk Status<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: "white" }}>
                        {patientData.map((sample,index) => {
                            return (
                            <>
                            <TableRow key={sample?.MPID} hover>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6'}}>{sample?.MPID}</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A" , borderRight:'1px solid #E6E6E6'}}>{sample.data['Height'] ? sample.data['Height'] + ' cm' : '-'  }</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A" , borderRight:'1px solid #E6E6E6'}}>{sample.data['Weight'] ? sample.data['Weight'] + ' kg' : '-'  }</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.data['AGE']}</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.data['Gravida']}</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.data['Category']}</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.data['Facility Type']}</TableCell>
                            <TableCell align='center' style={{ textAlign: "center" }}>
                                {
                                    sample?.risk_class !== 'H'
                                    ? <Chip label={'H'} style={{ backgroundColor: "#FF8585", color: "white",fontWeight: '600', width: "60px", height: "25px", fontWeight: "700" }} />
                                    : <Chip label={'L'} style={{ backgroundColor: "#58DFA3",color:'white',fontWeight: '600', width: "60px", height: "25px" }} variant="outlined"/>
                                }
                                </TableCell>

                            </TableRow>
                            </>
                        )
                        })}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
        </div>

    )
}
