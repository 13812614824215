import Loader from "../components/Loader";
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';


export default function EmptyTable ({ errorData }) {
    return (
        <TableContainer style={{ marginTop: "20px" }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Table aria-label="customized table" style={{ marginLeft: "35px" , marginRight: "30px", justifyContent: 'center' }} >
                    <TableHead style={{ backgroundColor: "#ebf9ef" }}>
                    </TableHead>
                    <Loader variant="linear" />
                    <TableBody>
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
    )
}
