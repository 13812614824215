import AppBar from "@material-ui/core/AppBar";
import { login } from "../actions/login";
import logo from "../constants/wiai_logo.svg"
import React, { useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


const useStyles = makeStyles((theme) => ({
  button: {
      // margin: theme.spacing(1),
      marginTop: "30px",
      color: "white",
      fontSize: "15px",
      width: "182px",
      height: "56px",
      paddingLeft: "30px",
      borderRadius: '14px',
      paddingRight: "30px",
      textTransform: "none",
      backgroundImage: "linear-gradient(180deg, #5591F8 0%, #5591F8 100%)",
  },
  cssTextField: {
    
    "& label.Mui-focused": {
        color: "#DADCE0",
      },
      "& .MuiInput-underline:after": {        

        borderBottomColor: "#9B9D9D",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root": {        
          borderRadius: '15px',

        "& fieldset": {
          borderColor: "#9B9D9D",
        },
        "&:hover fieldset": {
          borderColor: "#9B9D9D",
          borderWidth: "1px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#9B9D9D",
          borderWidth: "1px",
        },
        [theme.breakpoints.up('lg')]: {
          width: "420px"
        },
        [theme.breakpoints.down('md')]: {
          width: "220px"
        }
      },
  }
}))

const Login = () => {
    const theme = useTheme()
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    

    const classes = useStyles() 
    const history = useHistory()
    const dispatch = useDispatch()
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const errorText = useSelector((state) => state.loginActionsReducer?.loginError)
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    function handleLogin () {
      setSubmitted(true)
      if (username && password) {
          dispatch(login(username, password))
          .then(() => {
            if(JSON.parse(window.sessionStorage.getItem('loggedInUser')) !== "")
              history.push('/selectModel')
          })
      }
    };
  
    

    return (
    <>
    <Grid container style={{height: window.innerHeight}}>
        <Grid item xs={4} md={4} lg={5}>
          <AppBar 
            position="relative"
            elevation={0}
            style={{
              background: "#F8F8F8",
              height: "130px",
              display: "flex",
              justifyContent: "center"}} 
            >
              <Toolbar>
                <Grid item>
                <a href='https://www.wadhwaniai.org/'>
                  <img src={logo} alt="Logo" style={{ paddingLeft: "20px" , height:"8rem" , width:"14rem", marginLeft:'20%' }} />
                </a>
                </Grid>
              </Toolbar>
          </AppBar>
          <Grid container justify="center" alignItems="center" style={{height: "100%", backgroundColor: "#F8F8F8"}}>
              <Grid item xs={8} md={8} lg={8} style={{display: "flex", justifyContent: "center", marginRight:'10%'}}>
                
                <Grid container style={{marginTop: "20px"}}>
                  <Grid item xs={12} md={12} lg={12} style={{marginLeft:'7%'}}>
                    <Typography style={{
                            fontSize: "34px",
                            fontWeight: "500",
                            lineHeight: "32px",
                            letterSpacing: "0.1px",
                            alignItems: "center",
                            color: "#444",
                            marginBottom:'15px'
                            }}>
                            Welcome!
                            <br/>
                    </Typography>
                    <Typography style={{color:'#9B9D9D', fontStyle:'Italic', fontSize:'18px'}}>
                      to PROS Portal.
                    </Typography>
                  </Grid>
                  <Typography style={{marginTop:'30px',fontSize:'19px',marginLeft:'7%'}}>Username</Typography>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                  <TextField
                      className={classes.cssTextField}
                      variant="outlined"
                      // fullWidth
                      margin="normal"
                      id="username"
                      label="Enter username here"
                      name="username"
                      type="username"
                      // autoFocus
                      error={submitted && !username}
                      helperText={<Typography component={'span'} style={{ marginLeft: "-14px", fontSize: "10px" }}>{submitted && !username ? 'Username is required' : ' '}</Typography>}
                      onChange={e => setUserName(e.target.value)}
                    />
                  </Grid>
                    <Typography style={{fontSize:'19px', marginLeft:'7%'}}>Password</Typography>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
                    <TextField
                      className={classes.cssTextField}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      id="password"
                      label="Enter password here"
                      name="password"
                      autoComplete="password"
                      error={(submitted && !password) || (errorText !== "" && errorText !== undefined)}
                      helperText={<Typography component={'span'} style={{ marginLeft: "-14px", fontSize: "10px" }}>{submitted && !password ? 'Password is required' : errorText}</Typography>}
                      onChange={e => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
                    <Button className={classes.button} onClick={handleLogin} variant="contained" component="label" >
                          Sign in
                      <img src={require("../constants/arrow_right.svg")}  style={{height:'22px', width:'22px', marginLeft:'8px'}} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Typography style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.1px",
                      alignItems: "center",
                      color: "black",
                      opacity: 0.5,
                      // marginTop: "300px"
                      }}>
                      Contact Wadhwani AI if you face challenges logging in
                </Typography>
          </Grid>
        </Grid>
        <Grid item xs={8} md={8} lg={7} style={{backgroundColor: "#F9ECF0", height: "100%"}}>
          <div>
          <img src={require("../constants/pros_logo.svg")}  style={{height:'110%', width:'100%'}} />
          </div>
        </Grid>
        
    </Grid>
    </> 
     
    );
  }

export default Login;
