import "./App.css";

import { refreshState, saveState } from "./store/localStorage";

import { Provider } from "react-redux";
import React from "react";
import Routes from "./routes";
import { ThemeProvider } from "@material-ui/core/styles";
import configureStore from "./store/configureStore";
import theme from "./theme/materialTheme";
import NetworkError from "./components/NetworkError"

const persistedstate = refreshState();
const store = configureStore(persistedstate);

store.subscribe(() => {
  saveState(store.getState());
});

// const store = configureStore({});
function App () {
  const [open, setOpen] = React.useState(false);

  function handleConnectionChange (event) {
    if (event.type === "offline") {
        setOpen(true)
    }
    if (event.type === "online") {
        window.location.reload();
        setOpen(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    }
  })
  return (
    <>
    <NetworkError open={open} setOpen={setOpen}/>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes/>
      </ThemeProvider> 
    </Provider>
    </>
  );
}

export default App;
