import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import EmptyTable from "../components/EmptyTable";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { getPatientData} from "../actions/global";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuAppBar from "../components/AppBar";
import MuiAccordion from '@material-ui/core/ExpansionPanel';
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import PatientDataTable from "../components/PatientDataTable";
import PatientDataTable2 from "../components/PatientDataTable2";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination';

const Accordion = withStyles({
    root: {
      borderTop: "0.5px dotted #BDC1C6",
      borderBottom: "0.5px dotted #BDC1C6",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0
      },
      "&:before": {
        display: "none"
      },
      "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
      }
    },
    "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
    }
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
    //   backgroundColor: "yellow",
      // borderBottom: "1px solid yellow",
    //   marginBottom: -1,
      minHeight: 40,
      paddingLeft: "0px",
      paddingRight: "0px",
      "&$expanded": {
        minHeight: 40
      }
    },
    content: {
      "&$expanded": {
        margin: "0 0"
      }
    },
    "&$expanded": {
        marginTop: "0px",
        marginBottom: "0px"
      }
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      backgroundColor: "#F8F9FA",
    //   maxHeight: "120px",
      overflow: "auto",
    //   padding: "0px"
    }
  }))(MuiAccordionDetails);

const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#DADCE0",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#DADCE0",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
        "&:hover fieldset": {
          borderColor: "#DADCE0",
          borderWidth: "1px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#DADCE0",
          borderWidth: "1px",
        }
      },
    },
  })(TextField);


const CustomCheckbox = withStyles(theme => ({
    root: {
        color: "white",
        borderRadius: 4,
        border: "1px solid #E8EAED",
        backgroundColor: "white",
        "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0
        },
        "&$checked":{
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
              },
            "& .MuiIconButton-label:after": {
                content: '""',
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "#202124",
                zIndex: -1,
                // borderColor: "transparent"
              }

        },
        "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
        },
        
    },
    checked: {
        
    }
}))(Checkbox);


const CustomRadio = withStyles(theme => ({
    root: {
        color: "#5E6060",
        '&.Mui-checked': {
            color: "#5591F8",
          },
    }
}))(Radio);


const useStyles = makeStyles((theme) => ({
    cardPaper: {
        overflow: "auto",
        boxShadow: "2px 2px 2px 2px lightgrey",
        width: "100%",
        height: "130px",
        border: "1px solid #DADCE0",
        borderRadius: "20px",
        fontSize: "22px"
    },
    downloadButton: {
        margin: theme.spacing(1),
        marginLeft: "73%",
        color: "white",
        fontSize: "13px",
        height: "48px",
        borderRadius:'14px',
        padding:'14px 16px',
        backgroundImage: "linear-gradient(180deg, #5591F8 0%, #5591F8 100%)",
        
    },
    leftContainer: {
        backgroundColor:'#F8F8F8',
        borderRadius:'24px',
        position:'relative',
        left:'0.8rem'
    },
    searchButton: {
        color: "white",
        fontSize: "14px",
        height: "48px",
        width: "220px",
        borderRadius:'10px',
        marginTop: "35px",
        paddingLeft: "30px",
        paddingRight: "30px",
        padding: '12px 32px',

        backgroundImage: "linear-gradient(180deg, #5591F8 0%, #5591F8 100%)",
        
    },
    riskCheckBox: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        alignItems: "center",
        marginLeft: "5px",
        color: "#5E6060"
    },
    listCheckBox: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.1px",
        alignItems: "center",
        marginLeft: "10px",
        color: "#4A4A4A"
    }
}))

export default function RiskAnalysis () {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tableLoader, setTableLoader] = React.useState(false)
    const patientData = useSelector(state => state.globalActionsReducer.patientData)
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)
    const CategoryList = useSelector(state => state.globalActionsReducer.CategoryList)
    const GravidasList = useSelector(state => state.globalActionsReducer.GravidasList) 
    const DistrictList = useSelector(state => state.globalActionsReducer.DistrictList) 
    
    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [startDateError, setStartDateError] = React.useState("")
    const [endDateError, setEndDateError] = React.useState("")
    const [reportHeaders, setReportHeaders] = React.useState([]);
    const [reportData, setReportData] = React.useState([]);
    const [expanded, setExpanded] = React.useState("");
    const [page, setPage] = React.useState(1);
    
    const [initialRisk, setInitialRisk] = React.useState("")
    const [finalRisk, setFinalRisk] = React.useState("")
    const [AllFacilityChecked, setAllFacilityChecked] = React.useState("")
    const [AllGravidaChecked, setAllGravidaChecked] = React.useState("")

    const [CategoryCheckedList, setCategoryCheckedList] = React.useState({})
    const [GravidaCheckedList, setGravidaCheckedList] = React.useState({})
    const [DistrictCheckedList, setDistrictCheckedList] = React.useState({})

    const initialFilter = {
        "initial_risk": "",
        "Category": [],
        "gravida": [],
        "district":[]
    }
    const model_list = {
        "1" : "ANC Adherence",
        "2" : "Pregnancy Induced Hypertension (PIH)",
        "3" : "C-Section",
    }
        
    const [filters, setFilters] = React.useState({...initialFilter})

    const csvLink = useRef()

    useEffect(() => {
        document.body.style.backgroundColor = "#FFF";
      },[])

      useEffect(() => {
    
        setTableLoader(true)
    
        dispatch(getPatientData(startDate, endDate, filters, page)).then(() => {
            setTableLoader(false)
        });
        
        }, [page])
        
    useEffect(() => {
        CategoryList.forEach((Category) => {
            CategoryCheckedList[Category] = CategoryCheckedList?.[Category] ? true : false
        })
        setCategoryCheckedList({...CategoryCheckedList})
        GravidasList.forEach((gravida) => {
            GravidaCheckedList[gravida] = GravidaCheckedList?.[gravida] ? true: false
        })
        setGravidaCheckedList({...GravidaCheckedList})

        GravidasList.forEach((district) => {
            DistrictCheckedList[district] = DistrictCheckedList?.[district] ? true: false
        })
        setDistrictCheckedList({...DistrictCheckedList})
        
    }, [CategoryList, GravidasList,DistrictList])
    
    useEffect(() => {
        handleSearchClick();
    }, [])

    useEffect(() => {
        console.log("4")
        if (reportData.length>0)
            csvLink.current.link.click()

    }, [reportData])

    useEffect(() => {
        if(JSON.stringify(initialFilter) === JSON.stringify(filters))
        {
            setInitialRisk("")
            setFinalRisk("")
            setStartDate("")
            setEndDate("")
            setStartDateError("")
            setEndDateError("")
            CategoryList.forEach((Category) => {
                CategoryCheckedList[Category] = false;
            })
            setCategoryCheckedList({...CategoryCheckedList})
            GravidasList.forEach((gravida) => {
                GravidaCheckedList[gravida] = false;
            })
            setGravidaCheckedList({...GravidaCheckedList})
        }

    }, [filters])
    const handleFilterChange = (key, value) => {
        if (filters.hasOwnProperty(key))
            filters[key] = value;
        setFilters({...filters});
    };

    function toggleLocation(location, locationType){
        if(locationType === "Category"){
            CategoryCheckedList[location] = !CategoryCheckedList[location]
            setCategoryCheckedList({...CategoryCheckedList})
        }
        else if(locationType === "district"){
            DistrictCheckedList[location] = !DistrictCheckedList[location]
            setDistrictCheckedList({...DistrictCheckedList})
        }
        else{
            GravidaCheckedList[location] = !GravidaCheckedList[location]
            setGravidaCheckedList({...GravidaCheckedList})
        }

        if(filters[locationType].indexOf(location) === -1)
            filters[locationType].push(location)
        else
            filters[locationType].splice(filters[locationType].indexOf(location), 1)
        setFilters({...filters})
    }

    function handleSearchClick() {
        setTableLoader(true)
        dispatch(getPatientData(startDate, endDate, filters,page )).then(() => {
            setTableLoader(false)
        });
    }


    function toggleAllOption (type) {
        filters[type] = []

        if (type === "facility") {
            CategoryList.map((Category) => {
                if (!AllFacilityChecked) {
                    filters[type].push(Category)
                }

                CategoryCheckedList[Category] = !AllFacilityChecked
                setCategoryCheckedList({ ...CategoryCheckedList })
            })

            setAllFacilityChecked(!AllFacilityChecked)
        } else {
            GravidasList.map((gravida) => {
                if (!AllGravidaChecked) {
                    filters[type].push(gravida)
                }

                GravidaCheckedList[gravida] = !AllGravidaChecked
                setGravidaCheckedList({ ...GravidaCheckedList })
            })

            setAllGravidaChecked(!AllGravidaChecked)
        }
    }

    function handleDownload() {
        setReportHeaders([
            {label: 'MPID', key: 'MPID'},
            {label: 'height', key: 'height'},
            {label: 'weight', key: 'weight'},
            {label: 'Age', key: 'Age'},
            {label: 'Risk Status', key: 'risk_class'},
          ])
        let sampleDataList = []
        patientData.forEach(sample => {        

            let sampleData = {
                'MPID': sample?.MPID,
                'height': sample?.height,
                'weight': sample?.weight,
                'Age': sample?.age,
                'risk_class': sample?.risk_class,
            }
            sampleDataList.push(sampleData);
        });
        setReportData([...sampleDataList])
    }
    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    function handleTableLoad(patientData){
        var model_type = window.sessionStorage.getItem('model_type');
        if(model_type == '1'){
            return <PatientDataTable patientData={patientData}/> 
        }
        else{
            return <PatientDataTable2 patientData={patientData}/>
        }
    }

    return (
    <div >
    <MenuAppBar />
        <Grid container spacing={1}  style={{marginTop:'1.5rem',width:'95%', flexDirection:'row'}}>
            <Grid item xs={12} md={2} lg={2}  className={classes.leftContainer}>
                <Grid container justify="flex-end" style={{paddingRight:'15px'}}>
                    <Grid item xs={12} md={11} lg={11} style={{marginTop: "20px"}}>   
                      
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Typography
                            display="inline" 
                            style={{
                                    fontSize: "19px",
                                    fontWeight: "500",
                                    lineHeight: "32px",
                                    letterSpacing: "0.1px",
                                    alignItems: "center",
                                    color: "#444",
                                    marginTop: "2px",
                                    marginBottom: "2px"
                                    }}>    

                                    <img src={require("../constants/Filter_icon.svg")} style={{marginRight: "8px", height:'13px'}} alt="Logo" />
                                
                                    Filters
                            </Typography>
                            {
                                (JSON.stringify(initialFilter) !== JSON.stringify(filters) || startDate !== "" || endDate !== "") &&
                                <Button style={{backgroundColor: 'transparent', textTransform: "none"}} 
                                    onClick={() => setFilters({...initialFilter})}>
                                <Typography 
                                display="inline" 
                                style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.1px",
                                        alignItems: "center",
                                        color: "#5591F8",
                                        }}>
                                        Clear All
                                </Typography>
                                </Button>

                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={11} lg={11} style={{ marginTop: "20px" }}>
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel1"}
                            onChange={() => setExpanded((prev) => { return prev === "panel1" ? "" : "panel1" })}
                        >
                        <AccordionSummary id="panel1d-header" style={{ backgroundColor: "#F8F8F8", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#5E6060",
                                                }}>
                                                Risk Status
                            </Typography>
                            {expanded !== "panel1" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px" }}>
                        <Grid container justify="center" style={{ borderRadius: "4px", backgroundColor: "#F8F8F8", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", paddingTop: "10px" }}>
                                    <Typography style={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "32px",
                                            letterSpacing: "0.1px",
                                            alignItems: "center",
                                            color: "#5E6060",
                                            }}>
                                            Risk Status
                                    </Typography>
                            </Grid>

                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left" }}>
                                    <div style={{ display: "flex", marginTop: "5px", marginBottom: "20px" }}>
                                        <FormControl>
                                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                                            <RadioGroup
                                                id="initial_risk"
                                                name="initial risk"
                                                value={initialRisk}
                                                onChange={(event) => {
                                                    setInitialRisk(event.target.value)
                                                    handleFilterChange("initial_risk", event.target.value)
}}
                                                    >
                                                <FormControlLabel
                                                    value="Low"
                                                    control={<CustomRadio />}
                                                    label={<Typography className={classes.riskCheckBox}>
                                                            Low({riskFiltersInfo?.count_low_initial})
                                                            </Typography>}/>
                                                            <FormControlLabel
                                                    value="High"
                                                    control={<CustomRadio />}
                                                    label={<Typography className={classes.riskCheckBox}>
                                                                High({riskFiltersInfo?.count_high_initial})
                                                                </Typography>} />
                                                                <FormControlLabel
                                                    value="YTG"
                                                    control={<CustomRadio />}
                                                    label={<Typography className={classes.riskCheckBox}>
                                                                Not Generated({riskFiltersInfo?.count_not_generated_initial})
                                                                </Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                            </Grid>

                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>
                    
                    

                    <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel2"}
                            onChange={() => setExpanded((prev) => { return prev === "panel2" ? "" : "panel2" })}
                        >
                        <AccordionSummary id="panel2d-header" style={{ backgroundColor: "#F8F8F8", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                Facility Type
                            </Typography>
                            {expanded !== "panel2" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F8F8F8" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>

                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={(event) => toggleAllOption("facility")} checked={AllFacilityChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        CategoryList.map(
                                            (Category) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={Category} style={{ color:'white',padding: "1px" }}
                                                onChange={(event) => toggleLocation(event.target.id, "Category")} checked={!!CategoryCheckedList[Category]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {Category}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel3"}
                            onChange={() => setExpanded((prev) => { return prev === "panel3" ? "" : "panel3" })}
                        >
                        <AccordionSummary id="panel3d-header" style={{ backgroundColor: "#F8F8F8", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                Gravida
                            </Typography>
                            {expanded !== "panel3" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F8F8F8" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={() => toggleAllOption("gravida")} checked={AllGravidaChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        GravidasList.map(
                                            (gravida) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={gravida} style={{ color: "white", padding: "0px" }}
                                                onChange={(event) => toggleLocation(event.target.id, "gravida")} checked={!!GravidaCheckedList[gravida]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {gravida}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>

                    
                    <Grid item xs={12} md={11} lg={11}>
                        <Grid container justify="flex-start">
                            <Grid item >
                                <Button onClick={handleSearchClick} className={classes.searchButton} >Apply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} lg={10} style={{marginBottom: "20px", backgroundColor:'#F8F8F8', borderRadius:'24px', position:'relative', left:'2rem'}}>
            <div style={{display: "flex",marginTop: "20px"}}>
                <Typography style={{fontWeight:'500', fontSize:'22px', marginTop:'1rem' ,  paddingLeft:'2rem'}}>Total Patients : {(riskFiltersInfo?.total_patients_count || 0)}</Typography>
                <Button onClick={handleDownload} className={classes.downloadButton} startIcon={<GetAppOutlinedIcon></GetAppOutlinedIcon>}>Download</Button>
                <CSVLink headers={reportHeaders} data={reportData} filename={new Date().toLocaleString().replace(",","") + "_patient_data.xls"} ref={csvLink} target="_blank"/>
            </div>

                { tableLoader === true ? <EmptyTable /> :handleTableLoad(patientData) }
                <Pagination color="red" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', marginBottom:'20px' }} 
                count={Math.ceil((riskFiltersInfo?.total_patients_count) / 100)}             
                page={page} 
                onChange ={(e,value)=>{
                    setPage(value)
                    console.log(page)
            }}/>
            </Grid>

        </Grid>
    </div>
    )
}
