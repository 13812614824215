import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        // margin: theme.spacing(1),
        color: "white",
        fontSize: "14px",
        height: "40px",
        paddingLeft: "30px",
        paddingRight: "30px",
        textTransform: "none",
        backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)"
    },
    
}))


const PopupMessage = ({ showDialog, setShowDialog, message }) => {
    const classes = useStyles();

  return (
    <React.Fragment>
        <Dialog open={showDialog} maxWidth={false}>
            <Paper elevation={0} style={{ width: "450px", height: "130px", display: "flex", marginTop: "28px", flexDirection: "column" }}>
            <Typography style={{ fontSize: "22px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", alignItems: "center", marginBottom: "15px", marginLeft: "24px" }} gutterBottom>
                {message}
            </Typography>
            <Divider variant="middle" style={{ marginBottom: "20px" }}/>
            <DialogActions>
                <Button className={classes.button} style={{ width: "94px" }} onClick={() => { setShowDialog(false) }}>Done</Button>
            </DialogActions>
            </Paper>
        </Dialog>
    </React.Fragment>
  );
}

export default PopupMessage;
