// Remove these two lines
export const solutionsOptionsAPI = "http://15.206.95.198:8000/v1/solution-list/?format=json";

export const dataTableAPI = "http://15.206.95.198:8000/v1/data/?format=json&page=";
//
// export const SERVER_URL = "https://159e6c66-11a9-400e-ad47-58e746ad4a4d.mock.pstmn.io/"
// export const NEW_SERVER_URL = "http://127.0.0.1:8000/"

export const NEW_SERVER_URL = "https://prs-portal-api.wadhwaniai.org/"
export const SERVER_URL = "https://prs-portal-api.wadhwaniai.org/"

export const FETCH_TABLE = SERVER_URL + "fetch/"
export const FETCH_API = NEW_SERVER_URL + "portal/"
export const UPLOAD_API = NEW_SERVER_URL + "portal/upload-file"
export const LOGIN_API = NEW_SERVER_URL + "auth/login"
export const DOWNLOAD_DATA_API = NEW_SERVER_URL + "portal/download-user-files";

export const pageStates = {
    PATIENT_UPLOADED_VIEW: "patient_uploaded_view",
    HIGH_RISK_VIEW: "high_risk_view",
    PATIENT_DATA_ERROR: "patient_data_error"
}
