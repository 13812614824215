import {
    ThemeProvider,
    createMuiTheme,
} from "@material-ui/core/styles";

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress'
import PropTypes from "prop-types";
import React from 'react';

const theme = createMuiTheme({
    palette: {
      primary: { light: '#85c7f9', main: '#54b1f7', dark: '#249bf5', contrastText: '#fff' },
    },
});

export default function Loader ({ variant = "linear", size }) {
  return (
    variant === 'linear'
    ? <ThemeProvider theme={theme}>
        <LinearProgress style={{ height: "7px" }}/>
    </ThemeProvider>
    : <ThemeProvider theme={theme}>
      <CircularProgress size={size}/>
    </ThemeProvider>
  )
}

Loader.propTypes = {
  variant: PropTypes.oneOf(["linear", "circular"])
};
