export const SET_LOGIN_USER = "SET_LOGIN_USER";
export const LOGOUT = "LOGOUT";
export const SET_SOLUTIONS_OPTIONS = "SET_SOLUTIONS_OPTIONS";
export const SET_DATA_TABLE = "SET_DATA_TABLE";
export const SET_TOOL_SUPPORTED = "SET_TOOL_SUPPORTED"
export const SET_DATA_TYPE_SUPPORTED = "SET_DATA_TYPE_SUPPORTED"
export const SET_PATIENTS_DATA = "SET_PATIENTS_DATA"
export const SET_PATIENTS_ERRORS = "SET_PATIENTS_ERRORS"
export const SET_ERROR_DATA = "SET_ERROR_DATA"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
