import { SET_PATIENTS_DATA, SET_ERROR_DATA, SET_PATIENTS_ERRORS } from "../actions/types";

const initialState = {
  annotationOptionsFetched: false,
  annotationOptionsFetching: false,
  errannotationOptions: false,
  payload: [],
  patientData: [],
  patientErrors: [],
  riskFiltersInfo: {},
  errorData: [],
  CategoryList: [],
  GravidasList: []

};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PATIENTS_DATA:
      return Object.assign({}, state, {
        patientData: action.data?.Records?.length > 0 ? action.data.Records : [],
        riskFiltersInfo: action.data?.meta,
        CategoryList: action.data?.meta?.categories?.length > 0 ? action.data.meta.categories : [],
        DistrictList: action.data?.meta?.districts?.length > 0 ? action.data.meta.districts : [],        
        GravidasList: action.data?.meta?.gravidas?.length > 0 ? action.data.meta.gravidas : []
      });
    case SET_ERROR_DATA:
      return Object.assign({}, state, {
        errorData: action.data?.uploaded_files ? action.data?.uploaded_files : []
      });
    case SET_PATIENTS_ERRORS:
      return Object.assign({}, state, {
        patientErrors: action.data?.Records,
        riskFiltersInfo: action.data?.meta,
        TUList: action.data?.meta?.tbus?.length > 0 ? action.data.meta.tbus : [],
        PHIList: action.data?.meta?.phis?.length > 0 ? action.data.meta.phis : []
      });

    default:
      return state;
  }
};
