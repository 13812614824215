import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux'
import check from "../constants/check.svg"

export default function PatientDataTable ({ patientData }) {
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)
    const filled_circle = <img src={check} style={{border: '0.50px #54B288 solid',background: '#EBFFF6', borderRadius: 999,padding:'2px' }}/>

    function matching_yes(str){
        if (str == null){
            return false
        }
        return ((typeof(str) == "boolean" && str == true) || (typeof(str) == "string" && str.toLowerCase() == 'yes'))
    } 
    const blank_circle = <div style={{height:'15px',width:'15px', border: '0.50px #C2C2C2 solid',background: '#FBFBFB', borderRadius: 999,padding:'2px' }}/>
    return (
        <div>
        <TableContainer style={{ marginTop: "20px", maxHeight: "700px", overflowY: 'overlay' }}>
            <div style={{ display: 'flex', justifyContent: 'center'}}>


                <Table stickyHeader aria-label="customized table" style={{ marginLeft: "35px", marginRight: "30px"}}>
                    <TableHead >
                        <TableRow>
                        <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "10%", textAlign: "center", color: "#444", fontWeight: "700", borderTopLeftRadius:'16px', borderRight:'1px solid #E6E6E6' }}>MPID<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                        <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "15%", textAlign: "center", color: "#444", fontWeight: "700", borderRight:'1px solid #E6E6E6' }}>Visits Already Completed<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "10%", textAlign: "center", color: "#444", fontWeight: "700", borderRight:'1px solid #E6E6E6' }}>Gravida<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "20%", textAlign: "center", color: "#444", fontWeight: "700", borderRight:'1px solid #E6E6E6' }}>Facility Type<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "10%", textAlign: "center", color: "#444", fontWeight: "700", borderRight:'1px solid #E6E6E6' }}>Likely to<br/> complete all<br/> ANC Visits<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "10%", textAlign: "center", color: "#444", fontWeight: "700", borderRight:'1px solid #E6E6E6' }}>Likely to<br/> complete 1 <br/>ANC Visit<img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#EBF2FF", width: "10%", textAlign: "center", color: "#444", fontWeight: "700" ,borderTopRightRadius:'16px'}}>Expected No.<br/> of Visits <img src={require("../constants/up_down.svg")} style={{marginLeft: "8px", height:'15px', width:'8px'}} alt="Logo" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: "white" }}>
                        {patientData.map((sample,index) => {
                            return (
                            <>
                            <TableRow key={sample?.MPID} hover>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6',  }}>{sample?.MPID}</TableCell>
                            <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A" , borderRight:'1px solid #E6E6E6',display:'flex', justifyContent:'space-evenly'}}>{
                            
                            <>
                            {matching_yes(sample?.data['ANC1']) ? filled_circle : blank_circle}
                            {matching_yes(sample?.data['ANC2']) ? filled_circle : blank_circle}
                            {matching_yes(sample?.data['ANC3']) ? filled_circle : blank_circle}
                            {matching_yes(sample?.data['ANC4']) ? filled_circle : blank_circle}
                            </>
                            
                            }</TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.data['Gravida']}</TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#4A4A4A", borderRight:'1px solid #E6E6E6' }}>{sample?.data['Type Of Facility1']}</TableCell>
                                <TableCell align='center' style={{ textAlign: "center",backgroundColor: "#EBF2FF", borderRight:'1px solid #E6E6E6' }}>
                                {
                                    sample?.risk_class === 'H'
                                    ? <img src={require("../constants/yes.svg")} style={{marginRight: "8px", height:'15px'}} alt="Logo" />
                                    : <img src={require("../constants/cross.png")} style={{marginRight: "8px", height:'15px'}} alt="Logo" />
                                }
                                </TableCell>
                                <TableCell align='center' style={{ textAlign: "center",backgroundColor: "#EBF2FF", borderRight:'1px solid #E6E6E6' }}>
                                {/* {
                                    sample?.visit_count > 1
                                    ? <img src={require("../constants/cross.svg")} style={{marginRight: "8px", height:'15px'}} alt="Logo" />
                                    : <img src={require("../constants/yes.svg")} style={{marginRight: "8px", height:'15px'}} alt="Logo" />
                                    
                                } */}
                                -
                                </TableCell>
                                <TableCell align='center' style={{ textAlign: "center", color: "#5591F8", fontWeight:'600',backgroundColor: "#EBF2FF" }}>-</TableCell>

                            </TableRow>
                            </>
                        )
                        })}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
        </div>

    )
}
