import { SET_DATA_TYPE_SUPPORTED, SET_SOLUTIONS_OPTIONS, SET_TOOL_SUPPORTED, SET_PATIENTS_DATA, SET_ERROR_DATA, SET_PATIENTS_ERRORS } from "./types";

import { fetchApi, fetchUpload } from "./fetchApi"
import { solutionsOptionsAPI, FETCH_API, UPLOAD_API } from "../env";

export function setSolutionsOptions (data) {
  return {
    type: SET_SOLUTIONS_OPTIONS,
    data
  };
}
export function getSolutionsOptions () {
  return dispatch => {
    return fetchApi(solutionsOptionsAPI)
      .then(res => {
        dispatch(setSolutionsOptions(res));
      });
  };
}
export function setToolSupported (data) {
  return {
    type: SET_TOOL_SUPPORTED,
    data
  };
}

export function setDataTypeSupported (data) {
  return {
    type: SET_DATA_TYPE_SUPPORTED,
    data
  };
}

export function setPatientData (data) {
  return {
    type: SET_PATIENTS_DATA,
    data
  };
}

export function setPatientErrors (data) {
  return {
    type: SET_PATIENTS_ERRORS,
    data
  };
}

export function setErrorData (data) {
  return {
    type: SET_ERROR_DATA,
    data
  };
}

export function getPatientData (startDate, endDate, filters,page) {

  
  if (startDate) { filters.start_date = new Date(startDate).getTime() / 1000 }
  if (endDate) { filters.end_date = new Date(endDate).getTime() / 1000 }

  var filters_model = {...filters}
  filters_model['model_type'] = window.sessionStorage.getItem('model_type')

  return dispatch => {
    return fetchApi(FETCH_API + 'list-patient-records' + "?page="+page, "POST", JSON.stringify(filters_model), new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }))
      .then(res => {
        dispatch(setPatientData(res))
      });
  };
}
export function getPatientErrors (startDate, endDate, filters,page) {
  var params = {}

  if (startDate) { filters.start_date = new Date(startDate).getTime() / 1000 }
  if (endDate) { filters.end_date = new Date(endDate).getTime() / 1000 }

  var filters_model = {...filters}
  filters_model['model_type'] = window.sessionStorage.getItem('model_type')

  return dispatch => {
    return fetchApi(FETCH_API + 'list-patient-errors' + "?page="+page, "POST", JSON.stringify(filters_model), new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }))
      .then(res => {
        dispatch(setPatientErrors(res))
      });
  };
}

export function getErrorData (userId) {
  return dispatch => {
    var model_type = window.sessionStorage.getItem('model_type');
    return fetchApi(FETCH_API + 'get-uploaded-files' + '?model_type=' + model_type, "GET")
      .then(res => {
        dispatch(setErrorData(res))
      });
  };
}

export function uploadFile (notificationFile, comorbidityFile) {
  var formdata = new FormData();
  formdata.append("original_file", notificationFile)
  return dispatch => {
    return fetchUpload(UPLOAD_API, "POST", formdata)
      .then();
  };
}
