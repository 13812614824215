import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import EmptyTable from "../components/EmptyTable";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { getPatientErrors } from "../actions/global";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuAppBar from "../components/AppBar";
import MuiAccordion from '@material-ui/core/ExpansionPanel';
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import PatientDataTable from "../components/PatientDataTable";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import PatientErrorTable from '../components/PatientErrorTable';
import Pagination from '@material-ui/lab/Pagination';


const Accordion = withStyles({
    root: {
      borderTop: "0.5px dotted #BDC1C6",
      borderBottom: "0.5px dotted #BDC1C6",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0
      },
      "&:before": {
        display: "none"
      },
      "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
      }
    },
    "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
    }
  })(MuiAccordion);

const AccordionSummary = withStyles({
root: {
//   backgroundColor: "yellow",
    // borderBottom: "1px solid yellow",
//   marginBottom: -1,
    minHeight: 40,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&$expanded": {
    minHeight: 40
    }
},
content: {
    "&$expanded": {
    margin: "0 0"
    }
},
"&$expanded": {
    marginTop: "0px",
    marginBottom: "0px"
    }
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
root: {
    backgroundColor: "#F8F9FA",
//   maxHeight: "120px",
    overflow: "auto",
//   padding: "0px"
}
}))(MuiAccordionDetails);

const CustomCheckbox = withStyles(theme => ({
    root: {
        color: "white",
        borderRadius: 4,
        border: "1px solid #E8EAED",
        backgroundColor: "white",
        "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0
        },
        "&$checked":{
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
              },
            "& .MuiIconButton-label:after": {
                content: '""',
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "#202124",
                zIndex: -1,
                // borderColor: "transparent"
              }

        },
        "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
        },
        
    },
    checked: {
        
    }
}))(Checkbox);


const useStyles = makeStyles((theme) => ({
    cardPaper: {
        overflow: "auto",
        boxShadow: "2px 2px 2px 2px lightgrey",
        width: "100%",
        height: "130px",
        border: "1px solid #DADCE0",
        borderRadius: "20px",
        fontSize: "22px"
    },
    downloadButton: {
        marginLeft: "auto",
        marginRight: "2rem",
        marginTop: "-1rem",
        color: "white",
        fontSize: "13px",
        height: "48px",
        borderRadius:'14px',
        padding:'14px 16px',
        // backgroundImage: "linear-gradient(180deg, #5591F8 0%, #5591F8 100%)",

    },
    searchButton: {
        color: "white",
        fontSize: "14px",
        height: "48px",
        width: "220px",
        borderRadius:'10px',
        marginTop: "35px",
        paddingLeft: "30px",
        paddingRight: "30px",
        padding: '12px 32px',
        backgroundImage: "linear-gradient(180deg, #5591F8 0%, #5591F8 100%)",
    },
    
    leftContainer: {
        backgroundColor:'#F8F8F8',
        borderRadius:'24px',
        position:'relative',
        left:'0.8rem',
    },
    riskCheckBox: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        alignItems: "center",
        marginLeft: "5px",
        color: "white"
    },
    listCheckBox: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.1px",
        alignItems: "center",
        marginLeft: "10px",
        color: "#4A4A4A"
    }
}))

export default function PatientErrorsPage () {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tableLoader, setTableLoader] = React.useState(false)
    const patientErrors = []
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)
    const [ErrorCheckedList, setErrorCheckedList] = React.useState({})
    const [AllErrorChecked, setAllErrorChecked] = React.useState("")

    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [expanded, setExpanded] = React.useState("");
    const [page, setPage] = React.useState(1);
    const initialFilter = {
        "Error": [],
    }
    const [filters, setFilters] = React.useState({...initialFilter})
    const errorList = ['AGE','Height','Weight']
    useEffect(() => {
        handleSearchClick();
    }, [])


    useEffect(() => {
        document.body.style.backgroundColor = "#FFF";
      }, [])



    useEffect(() => {
    
        setTableLoader(true)
    
        dispatch(getPatientErrors(startDate, endDate,filters, page)).then(() => {
            setTableLoader(false)
        });
        
    }, [page])
    
    function handleSearchClick() {
        setTableLoader(true)
        dispatch(getPatientErrors(startDate, endDate,filters, page)).then(() => {
            setTableLoader(false)
        });
    }
    function handleDownload() {
        console.log("Download in progress")
    }


    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };
      
    function toggleAllError () {
        filters['Error'] = []

        patientErrors.map((error) => {
            if (!AllErrorChecked) {
                filters['Error'].push(error)
            }

            ErrorCheckedList[error] = !AllErrorChecked
            setErrorCheckedList({ ...ErrorCheckedList })
        })

        setAllErrorChecked(!AllErrorChecked)
    }

    function toggleErrors(error){

        ErrorCheckedList[error] = !ErrorCheckedList[error]
        setErrorCheckedList({...ErrorCheckedList})

        if (filters['Error'].indexOf(error) === -1) { filters['Error'].push(error) } else { filters['Error'].splice(filters['Error'].indexOf(error), 1) }
        setFilters({...filters})
    }

    return (
    <>
    <MenuAppBar />

        <Grid container spacing={0} justify="center" style={{marginTop:'1.5rem',width:'95%', flexDirection:'row'}} >
        <Grid item xs={12} md={2} lg={2}  className={classes.leftContainer}>
                <Grid container justify="flex-end" style={{paddingRight:'15px'}}>
                    <Grid item xs={12} md={11} lg={11} style={{marginTop: "20px"}}>   
                      
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Typography
                            display="inline" 
                            style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    lineHeight: "32px",
                                    letterSpacing: "0.1px",
                                    alignItems: "center",
                                    color: "#444",
                                    marginTop: "2px",
                                    marginBottom: "15px"
                                    }}>    

                                    <img src={require("../constants/Filter_icon.svg")} style={{marginRight: "8px", height:'13px'}} alt="Logo" />
                                
                                    Filters
                            </Typography>
                            {
                                (JSON.stringify(initialFilter) !== JSON.stringify(filters) || startDate !== "" || endDate !== "") &&
                                <Button style={{backgroundColor: 'transparent', textTransform: "none"}} 
                                    onClick={() => setFilters({...initialFilter})}>
                                <Typography 
                                display="inline" 
                                style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.1px",
                                        alignItems: "center",
                                        color: "red",
                                        }}>
                                        Clear All
                                </Typography>
                                </Button>

                            }
                        </div>
                    </Grid>
                    
                    

                    <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel2"}
                            onChange={() => setExpanded((prev) => { return prev === "panel2" ? "" : "panel2" })}
                        >
                        <AccordionSummary id="panel2d-header" style={{ backgroundColor: "#F8F8F8", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                Errors
                            </Typography>
                            {expanded !== "panel2" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F8F8F8" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>

                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={(event) => toggleAllError()} checked={AllErrorChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        errorList.map(
                                            (patient_error) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={patient_error} style={{ color:'white',padding: "1px" }}
                                                onChange={(event) => toggleErrors(event.target.id)} checked={!!ErrorCheckedList[patient_error]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {patient_error}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>

                    
                    <Grid item xs={12} md={11} lg={11}>
                        <Grid container justify="flex-start">
                            <Grid item >
                                <Button onClick={handleSearchClick} className={classes.searchButton} >Apply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={10} lg={10} style={{ marginBottom: "10px" ,backgroundColor: "#F8F8F8" , borderRadius:'24px', position:'relative', left:'2rem',padding:'40px 10px'}}>

                <div style={{display:'flex'}}>
                <Typography style={{fontWeight:'500', fontSize:'22px', marginLeft:'2rem'}}>Total Data Errors : {(riskFiltersInfo?.error_count || 0)}</Typography>
                <Button onClick={handleDownload} className={classes.downloadButton} startIcon={<GetAppOutlinedIcon></GetAppOutlinedIcon>} disabled>Download</Button>
                </div>

                { tableLoader === true ? <EmptyTable /> : <PatientErrorTable patientData={patientErrors}/> }
            <Pagination style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }} 
                count={Math.ceil((riskFiltersInfo?.error_count) / 100)}             
                page={page} 
                onChange ={(e,value)=>{
                    setPage(value)
                    console.log(page)
                }}/>

            </Grid>
        </Grid>
    </>
    )
}
