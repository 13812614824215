import React, { useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { createBrowserHistory } from 'history';
import anc_adherence from "../constants/anc_adherence.svg"
import pih from "../constants/pih.svg"
import csection from "../constants/csection.svg"

const history = createBrowserHistory()

function rerouteModel(model_name){
    window.sessionStorage.setItem('model_type', model_name)
    window.location.href = '/risk_analysis';
}
const selectModel = () => {
  return (
    <div style={{height:'1000px',display: 'flex',justifyContent: 'center',alignItems: 'center', backgroundColor:'#F8F8F8'}}>

        <div style={{borderRadius: '32px', display: 'flex', flexDirection: 'column', alignItems:'center', marginTop:'-8%', marginLeft:'10%' }}>
            <Typography style={{fontSize:'24px', fontWeight:'600', marginBottom:'5%', marginLeft:'-10%',color:'#5E6060',fontSize: 40, fontWeight: '500'}} >
              Select Model of Choice
            </Typography>

        <div style={{display:'flex', flexDirection:'row'}}>
        <img src={anc_adherence} onClick={()=> rerouteModel("1")} style={{height:'25%', width:'25%',cursor: 'pointer', marginRight:'50px'}} />
        <img src={pih} onClick={()=> rerouteModel("2")} style={{height:'25%', width:'25%',cursor: 'pointer', marginRight:'50px'}} />
        <img onClick={()=> rerouteModel("3")} src={csection} style={{height:'25%', width:'25%',cursor: 'pointer'}} />
        </div>
        </div>
        
        </div>
  );
};

export default selectModel;
