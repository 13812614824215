import { LOGOUT, SET_LOGIN_USER , LOGIN_FAILURE} from "../actions/types";

const loggedInUser = JSON.parse(window.sessionStorage.getItem('loggedInUser') && window.sessionStorage.getItem('loggedInUser') !== "undefined" ? window.sessionStorage.getItem('loggedInUser') : "{}");
const initialState = loggedInUser ? { loggedIn: true, loggedInUser, loginError: "" } : {};


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LOGIN_USER:
      return Object.assign({}, state, {
        loggedInUser: action.data,
        loggedIn: true,
        loginError: ""
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loggedIn: false,
        loginError: "Invalid username or password. Please try again."
      });
    case LOGOUT: {
      return Object.assign({}, state, {
        loggedInUser: "",
        loggedIn: false,
        loginError: ""
      });
    }

    default:
      return state;
  }
};
