import { Button, Grid, Typography } from "@material-ui/core";
import FileErrors from "../components/FileErrors";
import FilesDragAndDrop from "../components/FileDragDrop";
import { getErrorData, uploadFile } from "../actions/global";
import InfoIcon from '@material-ui/icons/Info';
import Loader from "../components/Loader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles} from '@material-ui/core/styles';
import MenuAppBar from "../components/AppBar";
import { MenuItem } from "@material-ui/core";
import PopupMessage from "../components/PopupMessage";
import Popover from "@material-ui/core/Popover"
import PublishIcon from '@material-ui/icons/Publish';
import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux'


const CssTextField = withStyles({
    root: {
    //   "& label.Mui-focused": {
    //     color: "#DADCE0",
    //   },
      "& .MuiOutlinedInput-notchedOutline": { 
        border: "none" 
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#DADCE0",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#50AFF7",
        },
        "&:hover fieldset": {
          borderColor: "#50AFF7",
          borderWidth: "1px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#50AFF7",
          borderWidth: "1px",
        },
        width: "170px",
        backgroundColor: "#F3F5FE",
        borderRadius: "10px",
        color: "#3C4043"
      },
    },
  })(TextField);

const useStyles = makeStyles((theme) => ({
    cardPaper: {
        overflow: "auto",
        boxShadow: "2px 2px 2px 2px lightgrey",
        width: "100%",
        height: "130px",
        border: "1px solid #DADCE0",
        borderRadius: "20px",
        fontSize: "22px"
    },
    button: {
        color: "white",
        fontSize: "14px",
        height: "45px",
        paddingLeft: "30px",
        paddingRight: "30px",
        textTransform: "none",
        borderRadius: "15px",
        background: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
        "&.Mui-disabled": {
            color: "white",
            background: "#BDC1C6",
            backgroundColor: "#BDC1C6",
        }
    },
    disabledButton: {
        color: "white",
        fontSize: "14px",
        height: "40px",
        paddingLeft: "30px",
        paddingRight: "30px",
        textTransform: "none",
        backgroundColor: "#BDC1C6",
        "&.Mui-disabled": {
            color: "white",
            backgroundColor: "blue",
        }
    },
    linearProgress: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0.9,
        borderRadius: 4,
        zIndex: 1,
      },
    bar: {
        background: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    popover: {
        "& .MuiPaper-root": {
            color: "white",
            backgroundColor: "black"
        }

    }
}))

export default function Upload () {
    const classes = useStyles();
    const [notificationFile, setNotificationFile] = React.useState();
    const [comorbidityFile, setComorbidityFile] = React.useState();
    const [uploadDisabled, setUploadDisabled] = React.useState(true);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [showPopup, setShowPopup] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    const [fileUploadMsg, setFileUploadMsg] = React.useState("");
    const [notificationPopup, setNotificationPopup] = React.useState(false);
    const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
    const [commorbPopup, setCommorbPopup] = React.useState(false);
    const [commorbAnchorEl, setCommorbAnchorEl] = React.useState(null);
    const [filterMenu, setFilterMenu] = React.useState(0)
    const [loaderProgress, setLoaderProgress] = React.useState(0);
    const [uploadComplete, setUploadComplete] = React.useState(false);

    const dispatch = useDispatch();

    const errorData = useSelector(state => state.globalActionsReducer.errorData)
    const [filteredErrorData, setFilteredErrorData] = React.useState([])

    const noti = React.useRef(null);

    useEffect(() => {
        document.body.style.backgroundColor = "#FFF"
        dispatch(getErrorData()).then(() => setShowLoader(false))
        // dispatch(getErrorData()).then(() => setTableLoader(false))
      },[dispatch])
    
    useEffect(() => {
        if (!notificationFile) 
            setUploadDisabled(true)
        else
            setUploadDisabled(false)
    }, [ notificationFile, setUploadDisabled, dispatch])

    useEffect(() => {
        errorData.sort((input1, input2) => {
            let date1 = new Date(input1.uploaded_at).getTime()
            let date2 = new Date(input2.uploaded_at).getTime()
            if(date1 > date2)
                return -1
            return 1
        })
        setFilteredErrorData(errorData)
    }, [errorData, setFilteredErrorData])

    useEffect(() => {
        if (filterMenu === 0){
            // All files
            setFilteredErrorData(errorData)
        }
        else if (filterMenu === 1) {
            // Files with error
            setFilteredErrorData([...errorData.filter(el => el.error_count > 0)])
            
        }
    }, [filterMenu])
    useEffect(() => {
        if(submitLoading && (uploadComplete || loaderProgress >= 70)){
            const timer = setInterval(() => {
                setLoaderProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(timer);
                    setSubmitLoading(false);
                    setUploadComplete(false);
                    setShowPopup(true);
                    setShowLoader(true);
                    setNotificationFile();
                    dispatch(getErrorData()).then(() => setShowLoader(false))
                    return 0;
                }
                const diff = Math.random() * 12;
                return Math.min(oldProgress + diff, 100);
            });
            }, 500);
    
            return () => {
            clearInterval(timer);
            };
        }
        if(submitLoading && loaderProgress < 70){
            const timer = setInterval(() => {
                setLoaderProgress((oldProgress) => {
                if (oldProgress >= 70) {
                    clearInterval(timer);
                }
                const diff = Math.random() * 4;
                return Math.min(oldProgress + diff, 100);
            });
            }, 100);
    
        return () => {
          clearInterval(timer);
        };
        }
      }, [submitLoading, uploadComplete]);

    function handleUpload(){
        setSubmitLoading(true);
        setUploadDisabled(true);
        dispatch(uploadFile(notificationFile)).then((res) => {
            setUploadComplete(true);
            setFileUploadMsg(res?.details)   
        }).then(() => {})

    }

    return (
    <>
    <MenuAppBar />
    <PopupMessage message={fileUploadMsg} showDialog={showPopup} setShowDialog={setShowPopup}/>
        <Grid container justify="center" style={{height: "100%",width:'90%', backgroundColor:'#F8F8F8', borderRadius:'48px', marginLeft:'5%', marginTop:'5%', padding:'3rem'}}>
            <Grid item xs={12} md={7} lg={8}>
                <div style={{textAlign: "center"}}>
                </div>
                <Grid container justify="center" style={{backgroundColor:'#FFF', borderRadius: '32px', border:'2px dashed #C3D4F5', width:'95%'}}>
                    <Grid item xs={4} md={5} lg={4} >
                        <Grid container justify="center">
                            <Grid item xs={11} md={11} lg={11} style={{height: "430px"}}>
                                <FilesDragAndDrop uploadedFile={notificationFile} setUploadedFile={setNotificationFile}/>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <div style={{display: "flex", justifyContent: "center", marginTop: "50px", position: "relative", right:'3%'}}>
                    <Button 
                    disabled={uploadDisabled} 
                    className={classes.button}
                    onClick={handleUpload} 
                    startIcon={<PublishIcon style={{position: "relative", zIndex: 2}}></PublishIcon>} 
                    variant="contained" 
                    component="label"
                    > 
                    <Typography style={{position: "relative", zIndex: 2}}>
                    {submitLoading ? "Upload file in progress..." : "Upload Files"}
                    </Typography>
                    {submitLoading && (
                        <LinearProgress variant="determinate" value={loaderProgress} classes={{ root: classes.linearProgress, bar: classes.bar }}/>
                    )}
                    </Button>
                </div>
            </Grid>
            
            <Grid item xs={12} md={5} lg={4} style={{ border: "1px solid #DADCE0", backgroundColor: "#F2F2F2", maxHeight: window.innerHeight, overflowY: "overlay", borderRadius: '24px'}}>
                <Grid container style={{justifyContent: "center", marginTop: "25px"}}>
                    <Grid item xs={11} md={11} lg={11}>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px"}}>
                        <Typography style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            lineHeight: "32px",
                            letterSpacing: "0.1px",
                            // alignItems: "center",
                            // verticalAlign: "middle",
                            color: "#5E6060",
                            
                            // marginBottom: "15px",
                            }}>
                            Files Uploaded
                        </Typography>
                        <CssTextField
                            select
                            size="small"
                            variant="outlined"
                            value={filterMenu}
                            onChange={e => {setFilterMenu(e.target.value)}}
                            style={{borderRadius: "15px"}}
                        >
                            <MenuItem key={0} value={0}>All files</MenuItem>
                            <MenuItem key={1} value={1}>Files with error</MenuItem>
                            
                        </CssTextField>
                        </div>
                        {showLoader && <Loader variant="linear" />}
                    </Grid>
                </Grid>
                <Grid container style={{justifyContent: "center", marginTop: "15px",  marginBottom: "20px"}}>
                    <Grid item xs={11} md={11} lg={11} style={{marginBottom: "20px", width:'80%'}}>
                        {
                            filteredErrorData.length > 0
                            ? filteredErrorData.map((file, index) => (
                                <FileErrors fileData={file} index={index} />
                            ))
                            :
                            !(showLoader) &&
                            <Typography style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                                alignItems: "center",
                                color: "#3C4043",
                                marginBottom: "15px"
                                }}>
                            No files with errors found
                            </Typography> 
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}
